<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>All DRS</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <router-link to="/drs/create">
          <v-btn small>Add DRS</v-btn>
        </router-link>
        &nbsp;
        <router-link to="/drs/create-simple">
          <v-btn small>Add Travel / Yard Work DRS</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <v-form @submit.prevent="getDrs">
          <v-row class="pt-8">
            <v-col class="col">
              <label>Job No</label>
              <v-text-field v-model="jobNumber"></v-text-field>
            </v-col>
            <v-col class="col">
              <label>DRS No</label>
              <v-text-field v-model="drsNumber"></v-text-field>
            </v-col>
            <v-col class="col">
              <label>Customer</label>
              <v-text-field v-model="customer"></v-text-field>
            </v-col>
            <v-col class="col">
              <label>Sales person</label>
              <v-text-field v-model="salesPerson"></v-text-field>
            </v-col>
            <v-col class="col">
              <label>Chargehand</label>
              <v-text-field v-model="chargehand"></v-text-field>
            </v-col>
            <v-col class="col">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label class="pl-8">Date</label>
                  <v-text-field
                    v-model="date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  range>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col class="col-3 pt-10">
              <v-btn type="sumbit">Search</v-btn>
              &nbsp;
              <v-btn @click="clearSearch">Clear</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Job No</th>
                <th class="text-left">DRS No</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Date</th>
                <th class="text-left">Site</th>
                <th class="text-left">Chargehand</th>
                <th class="text-left">By</th>
                <th class="text-left">Checked?</th>
                <th class="text-left">Hours added?</th>
                <th class="text-left">Use for pay?</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(drs, index) in dailyReturnSheets"
                :key="drs.id"
              >
                <td>{{ drs.jobNumber }}</td>
                <td>{{ drs.palNumber }}</td>
                <td>{{ drs.customer }}</td>
                <td>{{ drs.work_date | tinyDate }}</td>
                <td>{{ drs.site }}</td>
                <td>{{ drs.chargehand }}</td>
                <td>{{ drs.quotedByInitials }}</td>
                <td>
                  <v-checkbox @change="toggleChecked(index)" :ripple="false" v-model="drs.confirmed"></v-checkbox>
                </td>
                <td>
                  {{  drs.hasHours }}
                </td>
                <td>
                  <v-checkbox @change="toggleUseForPay(index)" :ripple="false" v-model="drs.send_to_pay_report"></v-checkbox>
                </td>
                <td class="pr-0">
                  <router-link custom v-slot="{ navigate }" :to="`/drs/view/${drs.id}`">
                    <v-btn
                      title="View"
                      text
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <router-link custom v-slot="{ navigate }" :to="`/drs/addHours/${drs.id}`">
                    <v-btn
                      title="View"
                      text
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        + Hours
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <router-link custom v-slot="{ navigate }" :to="`/drs/update/${drs.id}`">
                    <v-btn
                      title="View"
                      text
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        Update
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <v-btn
                    title="Delete"
                    text
                    x-small
                    @click="drsToDelete = index, confirmDeleteDrs = true"
                    role="link">
                      Delete
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col>
        <download-csv
            name="drs.csv"
            :data="dailyReturnSheets"
            :fields="exportFields">
          <v-btn>Export</v-btn>
        </download-csv>
      </v-col>
    </v-row>
    <router-view></router-view>
    <v-dialog
      v-model="confirmDeleteDrs"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          Are you sure you wish to delete this DRS?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="drsToDelete = -1, confirmDeleteDrs = false"
          >
            Close
          </v-btn>
          <v-btn
            text
            @click="deleteDrs"
            color="red">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'DrsIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      exportFields: [
          'jobNumber',
          'customer',
          'work_date',
          'site',
          'chargehand',
      ],
      dailyReturnSheets: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      date: [],
      dateDialogue: false,
      modal: false,
      customer: '',
      salesPerson: '',
      chargehand: '',
      jobNumber: '',
      drsNumber: '',
      drsToDelete: -1,
      confirmDeleteDrs: false,
    };
  },
  watch: {
    page() { this.getDrs(); },
    jobNumber() { this.setDrsSearchParams(); },
    customer() { this.setDrsSearchParams(); },
    salesPerson() { this.setDrsSearchParams(); },
    chargehand() { this.setDrsSearchParams(); },
    date() { this.setDrsSearchParams(); },
  },
  methods: {
    deleteDrs() {
      const drsId = this.dailyReturnSheets[this.drsToDelete].id;
      axios.get(`/dailyReturnSheets/delete/${drsId}.json?token=${this.token}`)
        .then(() => {
          this.dailyReturnSheets.splice(this.drsToDelete, 1);
          this.drsToDelete = -1;
          this.confirmDeleteDrs = false;
        });
    },
    setDrsSearchParams() {
      const params = {};
      params.jobNumber = this.jobNumber;
      params.customer = this.customer;
      params.salesPerson = this.salesPerson;
      params.chargehand = this.chargehand;
      params.date = this.date;
      this.$store.commit('setDrsSearchParams', params);
    },
    toggleChecked(index) {
      const drsId = this.dailyReturnSheets[index].id;
      axios.get(`/dailyReturnSheets/toggleChecked/${drsId}.json?token=${this.token}`);
    },
    toggleUseForPay(index) {
      const drsId = this.dailyReturnSheets[index].id;
      axios.get(`/dailyReturnSheets/toggleUseForPay/${drsId}.json?token=${this.token}`);
    },
    clearSearch() {
      this.jobNumber = '';
      this.customer = '';
      this.salesPerson = '';
      this.chargehand = '';
      this.date = [];
      this.getDrs();
    },
    getSearchParams() {
      this.jobNumber = this.$store.state.drsSearchParams.jobNumber;
      this.customer = this.$store.state.drsSearchParams.customer;
      this.salesPerson = this.$store.state.drsSearchParams.salesPerson;
      this.chargehand = this.$store.state.drsSearchParams.chargehand;
      this.date = this.$store.state.drsSearchParams.date;
    },
    getDrs() {
      setTimeout(() => {
        const postData = {};
        postData.jobNumber = this.jobNumber;
        postData.drsNumber = this.drsNumber;
        postData.customer = this.customer;
        postData.salesPerson = this.salesPerson;
        postData.chargehand = this.chargehand;
        postData.date = this.date;
        axios.post(`/dailyReturnSheets/getAll/${this.page}.json?token=${this.token}`, postData)
          .then((response) => {
            this.dailyReturnSheets = response.data.dailyReturnSheets;
            this.length = response.data.length;
          })
          .catch((error) => {
            console.log(error);
          });
      }, 100);
    },
  },
  mounted() {
    this.getSearchParams();
    this.getDrs();
  },
};
</script>
