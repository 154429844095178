<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>All Invoices</h1>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">&nbsp;</th>
                <th class="text-left">No</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Address</th>
                <th class="text-left">Date</th>
                <th class="text-left">Value</th>
                <th class="text-left">Vat</th>
                <th class="text-left">O/S</th>
                <th class="text-left">Paid</th>
                <th class="text-left">Discount</th>
                <th class="text-left">Retention</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="invoice in invoices"
                :key="invoice.id"
              >
                <td>{{ invoice.statusLetter }}</td>
                <td>{{ invoice.invoiceNumber }}</td>
                <td>{{ invoice.customer }}</td>
                <td>{{ invoice.siteLocation }}</td>
                <td>{{ invoice.date | tinyDate }}</td>
                <td>{{ invoice.value | priceInPounds | currency }}</td>
                <td>{{ invoice.vat | priceInPounds | currency }}</td>
                <td>{{ invoice.outstanding | priceInPounds | currency }}</td>
                <td>{{ invoice.amountPaid | priceInPounds | currency }}</td>
                <td>{{ invoice.discount | priceInPounds | currency }}</td>
                <td>{{ invoice.retention | priceInPounds | currency }}</td>
                <td class="pr-0">
                  <router-link
                    v-if="userPermissions.includes(4)"
                    custom
                    v-slot="{ navigate }"
                    :to="`/invoices/view-draft/${invoice.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                  <router-link
                    v-else
                    custom
                    v-slot="{ navigate }"
                    :to="`/invoices/print-view/${invoice.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col>
        <download-csv
            name="invoices.csv"
            :data="invoices"
            :fields="exportFields">
          <v-btn>Export</v-btn>
        </download-csv>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicesIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  data() {
    return {
      exportFields: [
          'invoiceNumber',
          'customer',
          'date',
          'prettyValue',
          'prettyVat',
      ],
      invoices: [],
      page: 1,
      length: 0,
      totalVisible: 9,
    };
  },
  watch: {
    page() {
      this.getInvoices();
    },
  },
  methods: {
    getInvoices() {
      axios.get(`/invoices/getAll/${this.page}.json?token=${this.token}`)
        .then((response) => {
          this.invoices = response.data.invoices;
          this.length = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getInvoices();
  },
};
</script>
