<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Expired Quotations</h1>
        <v-form @submit.prevent="setSearchParams">
          <v-row class="pt-8">
            <v-col>
              <label>Job No</label>
              <v-text-field
                  placeholder="i.e. 123432-1-DR"
                  v-model="jobNumber"></v-text-field>
            </v-col>
            <v-col>
              <label>Customer</label>
              <v-text-field v-model="customer"></v-text-field>
            </v-col>
            <v-col>
              <label>Sales Initials</label>
              <v-text-field
                  placeholder="Initials i.e. DR"
                  v-model="salesPerson"></v-text-field>
            </v-col>
            <v-col>
              <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label class="pl-8">Date</label>
                  <v-text-field
                      v-model="date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateProto"
                    range>
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col>
              <label>Site search</label>
              <v-text-field
                  placeholder="Address"
                  v-model="jobSite"></v-text-field>
            </v-col>
            <v-col>
              <label>Needs approval?</label>
              <v-select v-model="approval" :items="yesNo"></v-select>
            </v-col>
            <v-col class="pt-10">
              <v-btn small type="sumbit">Search</v-btn>
              &nbsp;
              <v-btn small @click="clearSearch">Clear</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <h1>
          Expired Quotations
          <span class="float-right">
            Total value:
            {{ total | priceInPounds | currency }}
          </span>
        </h1>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">No</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Date</th>
                <th class="text-left">Site</th>
                <th class="text-left">Expired</th>
                <th class="text-left">Converted?</th>
                <th class="text-left">Value</th>
                <th class="text-left">By</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="quotation in quotations"
                :key="quotation.id"
              >
                <td>{{ quotation.jobNumber }} {{ quotation.expired }}</td>
                <router-link :to="`/customers/view/${quotation.customerId}`">
                  {{ quotation.customer }}
                </router-link>
                <td>{{ quotation.date | tinyDate }}</td>
                <td>{{ quotation.site }}</td>
                <td>{{ quotation.expires | tinyDate }}</td>
                <td>
                  <span class="text-green text-heavy" v-if="quotation.converted === true">Yes</span>
                  <span class="text-red text-heavy" v-else>No</span>
                </td>
                <td>
                  <span v-if="quotation.value > quotation.minChargeAmount">
                    {{ quotation.value | priceInPounds | currency }}
                  </span>
                  <span v-else>
                    {{ quotation.minChargeAmount | priceInPounds | currency }}
                  </span>
                </td>
                <td>{{ quotation.quotedByInitials }}</td>
                <td class="pr-0">
                  <router-link custom v-slot="{ navigate }" :to="`/quotations/view/${quotation.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col>
        <download-csv
            name="quotations.csv"
            :data="quotations"
            :fields="exportFields">
          <v-btn>Export</v-btn>
        </download-csv>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'QuotationsIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      approval: 0,
      date: [],
      dateProto: [],
      dateDialogue: false,
      exportFields: [
        'customer',
        'date',
        'jobNumber',
        'site',
        'siteContact',
        'siteTel',
        'siteEmail',
        'description',
        'valueInPounds',
      ],
      modal: false,
      quotations: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      total: 0,
      jobNumber: '',
      customer: '',
      salesPerson: '',
      jobSite: '',
      yesNo: [
        { value: 0, text: 'All' },
        { value: 1, text: 'Yes' },
        { value: 2, text: 'No' },
      ],
    };
  },
  watch: {
    page() {
      this.getQuotations();
    },
    dateProto () {
      this.date = this.formatDate(this.dateProto);
    },
  },
  methods: {
    formatDate(date) {
      const formattedDate = [];
      if (!date) return null;
      if (date[0]) {
        const [year, month, day] = date[0].split('-');
        formattedDate[0] = `${day}-${month}-${year}`;
      }
      if (date[1]) {
        const [year, month, day] = date[1].split('-');
        formattedDate[1] = `${day}-${month}-${year}`;
      }
      return formattedDate;
    },
    clearSearch() {
      this.approval = 0;
      this.customer = '';
      this.date = [];
      this.jobNumber = '';
      this.jobSite = '';
      this.quotations = [];
      this.salesPerson = 0;
      this.setSearchParams();
    },
    getSearchParams() {
      this.approval = this.$store.state.quotationSearchParams.approval;
      this.customer = this.$store.state.quotationSearchParams.customer;
      this.date = this.$store.state.quotationSearchParams.date;
      this.jobNumber = this.$store.state.quotationSearchParams.jobNumber;
      this.jobSite = this.$store.state.quotationSearchParams.jobSite;
      this.salesPerson = this.$store.state.quotationSearchParams.salesPerson;
    },
    setSearchParams() {
      const params = {};
      params.approval = this.approval;
      params.customer = this.customer;
      params.date = this.date;
      params.jobNumber = this.jobNumber;
      params.jobSite = this.jobSite;
      params.salesPerson = this.salesPerson;
      this.$store.commit('setQuotationSearchParams', params);
      this.getQuotations();
    },
    getQuotations() {
      const postData = {
        approval: this.approval,
        jobNumber: this.jobNumber,
        customer: this.customer,
        date: this.date,
        site: this.jobSite,
        salesPerson: this.salesPerson,
      };
      axios.post(`/quotations/getExpired/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.quotations = response.data.quotations;
          this.length = response.data.length;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getQuotations();
    this.getSearchParams();
    setTimeout(() => {
      this.getQuotations();
    }, 500);
  },
};
</script>
