<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>
          Search Invoices
        </h1>
        <v-form @submit.prevent="getInvoices">
          <v-row class="pt-8">
            <v-col>
              <label>Include draft?</label>
              <v-simple-checkbox :ripple="null" v-model="includeDraftInvoices"></v-simple-checkbox>
            </v-col>
            <v-col>
              <label>Customer</label>
              <v-text-field v-model="customer"></v-text-field>
            </v-col>
            <v-col>
              <label>Sage Ref</label>
              <v-text-field v-model="sageRef"></v-text-field>
            </v-col>
            <v-col>
              <label>Inv No</label>
              <v-text-field v-model="invoiceNumber"></v-text-field>
            </v-col>
            <v-col>
            <label>Sales initials</label>
              <v-text-field v-model="salesPerson"></v-text-field>
            </v-col>
            <v-col>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label class="pl-8">Date</label>
                  <v-text-field
                    v-model="date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  range>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col>
              <label>Paid?</label>
              <v-select class="pt-3" :items="paymentStatuses" v-model="paymentStatus">
              </v-select>
            </v-col>
            <v-col>
              <v-btn class="mb-2" x-small block type="sumbit">Search</v-btn>
              <v-btn class="mb-2" x-small block @click="clearSearch">Clear</v-btn>
              <v-btn x-small block @click="exportResults">Export</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col>
            <h2>Total value of searched invoices: {{ totalValue | priceInPounds | currency }}</h2>
          </v-col>
        </v-row>
        <v-simple-table
          v-if="invoices.length > 0"
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th class="text-left">No</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Address</th>
                <th class="text-left">Date</th>
                <th class="text-left">Value</th>
                <th class="text-left">Vat</th>
                <th class="text-left">O/S</th>
                <th class="text-left">Paid</th>
                <th class="text-left">Discount</th>
                <th class="text-left">Retention</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th colspan="12" class="text-right">
                  Page total: {{ pageValue | priceInPounds | currency }}
                </th>
              </tr>
            </tfoot>
            <tbody>
              <tr v-for="invoice in invoices"
              :key="invoice.id">
                <td>{{ invoice.statusLetter }}</td>
                <td>{{ invoice.jobNumber }}</td>
                <td>
                  <router-link :to="`/customers/view/${invoice.customerId}`">
                    {{ invoice.customer }}
                  </router-link>
                </td>
                <td>{{ invoice.siteLocation }}</td>
                <td>{{ invoice.date | tinyDate }}</td>
                <td>{{ invoice.value | priceInPounds | currency }}</td>
                <td>{{ invoice.vat | priceInPounds | currency }}</td>
                <td
                  v-if="invoice.type === 'invoice'"
                  class="amount-paid"
                  :class="{ outstanding: (invoice.outstanding > 0)}">
                  {{ invoice.outstanding | priceInPounds | currency }}
                </td>
                <td v-if="invoice.type === 'invoice'">{{ invoice.amountPaid | priceInPounds | currency }}</td>
                <td v-if="invoice.type === 'invoice'">{{ invoice.discount | priceInPounds | currency }}</td>
                <td v-if="invoice.type === 'invoice'">{{ invoice.retention | priceInPounds | currency }}</td>
                <td
                  v-if="invoice.type === 'creditNote'"
                  class="text-center">
                  &nbsp;
                </td>
                <td
                  v-if="invoice.type === 'creditNote'"
                  class="text-center">
                  Credit
                </td>
                <td
                  v-if="invoice.type === 'creditNote'"
                  class="text-center">
                  Note
                </td>
                <td
                  v-if="invoice.type === 'creditNote'"
                  class="text-center">
                  &nbsp;
                </td>
                <td>
                  <router-link
                    v-if="invoice.type === 'invoice' && userPermissions.includes(4)"
                    custom
                    v-slot="{ navigate }"
                    :to="`/invoices/view-draft/${invoice.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                  <router-link
                    v-if="invoice.type === 'invoice' && userPermissions.includes(9)"
                    custom
                    v-slot="{ navigate }"
                    :to="`/invoices/print-view/${invoice.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                  <router-link
                    v-if="invoice.type !== 'invoice'"
                    custom
                    v-slot="{ navigate }"
                    :to="`/invoices/view-credit-note/${invoice.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      View
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          v-if="invoices.length > 0"
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicesSearch',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
        return this.$store.state.userid;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  data() {
    return {
      invoices: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      date: [],
      dateDialogue: false,
      modal: false,
      customer: '',
      invoiceNumber: '',
      totalValue: 0,
      pageValue: 0,
      salesPerson: '',
      sageRef: '',
      includeDraftInvoices: true,
      paymentStatus: 1,
      paymentStatuses: [
        { value: 1, text: 'All' },
        { value: 2, text: 'Paid' },
        { value: 3, text: 'Unpaid' },
      ],
    };
  },
  watch: {
    page() { this.getInvoices(); },
  },
  methods: {
    exportResults() {
      const postData = {
        invoiceNumber: this.invoiceNumber,
        customer: this.customer,
        date: this.date,
        salesPerson: this.salesPerson,
        sageRef: this.sageRef,
        includeDraftInvoices: this.includeDraftInvoices,
        paymentStatus: this.paymentStatus,
      };
      axios.post(`/spreadsheets/searchedInvoices/${this.page}.json?token=${this.token}`, postData, {responseType:'blob'})
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `invoice_search_results.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    clearSearch() {
      this.invoices = [];
      this.jobNumber = '';
      this.customer = '';
      this.date = [];
      this.totalValue = 0;
      this.pageValue = 0;
      this.salesPerson = '';
      this.sageRef = '';
      this.paymentStatus = 1;
      this.includeDraftInvoices = true;
      this.setInvoiceSearchParams();
    },
    setInvoiceSearchParams() {
      const params = {};
      params.invoiceNumber = this.invoiceNumber;
      params.customer = this.customer;
      params.date = this.date;
      params.salesPerson = this.salesPerson;
      params.sageRef = this.sageRef;
      params.paymentStatus = this.paymentStatus;
      params.includeDraftInvoices = this.includeDraftInvoices;
      this.$store.commit('setInvoiceSearchParams', params);
    },
    getSearchParams() {
      this.invoiceNumber = this.$store.state.invoiceSearchParams.invoiceNumber;
      this.customer = this.$store.state.invoiceSearchParams.customer;
      this.date = this.$store.state.invoiceSearchParams.date;
      this.salesPerson = this.$store.state.invoiceSearchParams.salesPerson;
      this.sageRef = this.$store.state.invoiceSearchParams.sageRef;
      this.paymentStatus = this.$store.state.invoiceSearchParams.paymentStatus;
      this.includeDraftInvoices = this.$store.state.invoiceSearchParams.includeDraftInvoices;
      this.getInvoices();
    },
    getInvoices() {
      this.setInvoiceSearchParams();
      this.totalValue = 0;
      const postData = {
        invoiceNumber: this.invoiceNumber,
        customer: this.customer,
        date: this.date,
        salesPerson: this.salesPerson,
        sageRef: this.sageRef,
        paymentStatus: this.paymentStatus,
        includeDraftInvoices: this.includeDraftInvoices,
      };
      axios.post(`/invoices/getSearched/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.invoices = response.data.invoices;
          this.length = response.data.length;
          this.totalValue = response.data.totalValue;
          this.pageValue = response.data.pageValue;
        })
        .catch((error) => {
          console.log(error);
          this.hide = false;
        });
    },
  },
  mounted() {
    this.getSearchParams();
  },
};
</script>

<style lang="scss">
  .amount-paid {
    color: green;
    &.outstanding {
      color: tomato;
    }
  }
</style>
