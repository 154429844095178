<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>
          Planned Jobs
          <span class="float-right">
            Total value:
            {{ total | priceInPounds | currency }}
          </span>
        </h1>
        <p>These jobs have been planned, but not yet completed</p>
        <v-form @submit.prevent="getJobs">
          <v-row class="pt-8">
            <v-col class="col">
              <label>Job No</label>
              <v-text-field
                placeholder="i.e. 123432-1-DR"
                v-model="jobNumber"></v-text-field>
            </v-col>
            <v-col class="col">
              <label>Customer</label>
              <v-text-field v-model="customer"></v-text-field>
            </v-col>
            <v-col class="col">
              <label>Sales person's initials</label>
              <v-text-field
                placeholder="Initials i.e. DR"
                v-model="salesPerson"></v-text-field>
            </v-col>
            <v-col class="col">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label class="pl-8">Date</label>
                  <v-text-field
                    v-model="date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  range>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col class="col">
              <label>Site search</label>
              <v-text-field
                  placeholder="Address"
                  v-model="jobSite"></v-text-field>
            </v-col>
            <v-col class="col pt-10">
              <v-btn type="sumbit">Search</v-btn>
              &nbsp;
              <v-btn @click="clearSearch">Clear</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Job No</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Date</th>
                <th class="text-left">Site</th>
                <th class="text-left">Value</th>
                <th class="text-center">DRS?</th>
                <th class="text-left">By</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="job in jobs"
                :key="job.id"
              >
                <td>{{ job.jobNumber }}</td>
                <td>{{ job.customer }}</td>
                <td>{{ job.date | tinyDate }}</td>
                <td>{{ job.site }}</td>
                <td>
                  <span v-if="job.value > job.minChargeAmount">
                    {{ job.value | priceInPounds | currency }}
                  </span>
                  <span v-else>
                    {{ job.minChargeAmount | priceInPounds | currency }}
                  </span>
                </td>
                <td class="text-center">
                  <span v-if="job.paint_completed">
                    <span v-if="job.paint_completed === 'Yes'">
                      <v-icon color="green">mdi-brush-variant</v-icon>
                    </span>
                    <span v-else>
                      <v-icon color="red">mdi-brush-variant</v-icon>
                    </span>
                  </span>
                  <span v-if="job.thermo_completed">
                    <span v-if="job.thermo_completed === 'Yes'">
                      <v-icon color="green">mdi-fire</v-icon>
                    </span>
                    <span v-else>
                      <v-icon color="red">mdi-fire</v-icon>
                    </span>
                  </span>
                </td>
                <td>{{ job.quotedByInitials }}</td>
                <td class="pr-0">
                  <router-link custom v-slot="{ navigate }" :to="`/live-jobs/overview/${job.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col>
        <download-csv
            name="jobs.csv"
            :data="jobs"
            :fields="exportFields">
          <v-btn>Export</v-btn>
        </download-csv>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'PlannedJobs',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    }
  },
  data() {
    return {
      exportFields: [
        'customer',
        'date',
        'jobNumber',
        'site',
        'siteContact',
        'siteTel',
        'siteEmail',
        'description',
        'valueInPounds',
      ],
      jobSite: '',
      jobs: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      total: 0,
      date: [],
      dateDialogue: false,
      modal: false,
      customer: '',
      salesPerson: '',
      jobNumber: '',
    };
  },
  watch: {
    page() {
      this.getJobs();
    },
  },
  methods: {
    clearSearch() {
      this.jobSite = '';
      this.jobNumber = '';
      this.customer = '';
      this.salesPerson = '';
      this.date = [];
      this.getJobs();
    },
    getJobs() {
      const postData = {};
      postData.address = this.jobSite;
      postData.jobNumber = this.jobNumber;
      postData.customer = this.customer;
      postData.salesPerson = this.salesPerson;
      postData.date = this.date;
      axios.post(`/liveJobs/getPlanned/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.jobs = response.data.jobs;
          this.length = response.data.length;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getJobs();
  },
};
</script>
